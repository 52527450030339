import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'

export default function MusicArtistSkeleton(props) {
  return (
    <div
      key={props.index + 'loading-ele'}
      className="turning-screens music-artists 2xl:w-2/12 xl:w-5/12 lg:w-3/12 md:w-4/12  "
    >
      <div className="author">
        <a style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
          <div
            className="author__cover author__cover--bg"
            style={{ backgroundColor: 'transparent!important' }}
          >
            <Skeleton
              animation="wave"
              height={120}
              width="100%"
              style={{
                borderTopRightRadius: '15px',
                borderTopLeftRadius: '15px',
              }}
            />
          </div>
        </a>
        <div className="author__meta" style={{ display: 'flex' }}>
          <div className="artist_followers_tag">
            <Skeleton animation="wave" height={12} width="20%" />{' '}
          </div>

          <a style={{ display: 'block' }}>
            <>
              <div
                className={'author__avatar'}
                style={{
                  borderTopRightRadius: '15px',
                  borderTopLeftRadius: '15px',
                }}
              >
                <Skeleton animation="wave" height={60} width="100%" />{' '}
              </div>
              <h3
                className="author__name text_in_one_line"
                style={{ width: '100px' }}
              >
                <Skeleton animation="wave" height={15} width="100%" />{' '}
              </h3>
              <p className="author__nickname" style={{ width: '150px' }}>
                <Skeleton animation="wave" height={12} width="100%" />{' '}
              </p>
            </>
          </a>
        </div>
      </div>
    </div>
  )
}
