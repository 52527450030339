import React from "react";
import Link from "next/link";
import Tooltip from '@material-ui/core/Tooltip';

export default function MusicArtist(props) {
  return (
    <>
      <div className="author">
        <Link prefetch={false} href={"/artist/" + props.ele.id}>
          <a
            style={{ display: "flex", width: "100%", flexDirection: "column" }}
          >
            <div
              className="author__cover author__cover--bg"
              style={{
                background: props.ele.thumbnailImage
                  ? "url(" + props.ele.thumbnailImage+"_214x214?alt=media" + ")"
                  : "#ff9700",
              }}
            ></div>
          </a>
        </Link>
        <div className="author__meta" style={{ display: "block" }}>
          <div className="artist_followers_tag">
            {props.ele && props.ele.followers ? props.ele.followers : 0}{" "}
            followers
          </div>
          {props.state && props.state === "REJECT" && (
            <h3 className="artist_followers_reject">Rejected</h3>
          )}

          <Link prefetch={false} href={"/artist/" + props.ele.id}>
            <a style={{ display: "block" }}>
              <>
                <div className={"author__avatar"}>
                  <img
                    src={
                      props.ele.photoUrl
                        ? props.ele.photoUrl+"_250x250?alt=media"
                        : "/img/unknow.jpg"
                    }
                    alt="profile image"
                  />
                </div>
                <Tooltip title={props.ele?.name} >
                <h3 className="author__name text_in_one_line">
                  {props.ele.name
                    ? props.ele.name.length > 20
                      ? props.ele.name.substring(0, 20) + "..."
                      : props.ele.name
                    : "-"}
                </h3>
                </Tooltip>
                <Tooltip title={props.ele?.bio} >
                <p className="author__nickname">
                  {props.ele.bio
                    ? props.ele.bio.length > 20
                      ? props.ele.bio.substring(0, 20) + "..."
                      : props.ele.bio
                    : "unknown"}
                </p>
                </Tooltip>
              </>
            </a>
          </Link>
        </div>
      </div>
    </>
  );
}
