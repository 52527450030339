import React, { useEffect, useState } from 'react';
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore/lite';
import MusicArtist from './MusicArtist';
import MusicArtistSkeleton from '../skeletons/artistskeleton';
import { firebaseFireStore } from '../../config/firebaseFirestore';

export default function MusicArtistContainer() {
  const [loading, setloading] = useState(true);
  const [artistsData, setArtistsData] = useState([]);

  useEffect(() => {
    getTopMusicArtists();
  }, []);

  const getTopMusicArtists = async () => {
    const musicArtistData = [];
    try {
      setloading(true);
      const musicartArtistsRef = await getDocs(
        query(
          collection(firebaseFireStore, 'musicartArtists'),
          where('adminState', '==', 'APPROVED'),
          where('isMusicNftExits', '==', true),
          orderBy('followers', 'desc'),
          limit(6),
        ),
      );

      musicartArtistsRef.forEach((doc) => {
        const data = doc.data();
        musicArtistData.push({ ...data, id: doc.id });
      });

      console.log('musicArtistData', musicArtistData);

      setArtistsData(musicArtistData);
    } catch (error) {
      console.log('musicArtistData error', error, error.data);
    } finally {
      setloading(false);
    }
  };

  return (
    <>
      <section className="center_container">
        <div className="container home_container mx-auto">
          <div className="main__title main__title--page px-4">
            <h2>
              New Artists
              <img
                src="img/guitar_2.svg"
                style={{ width: 35, height: 35, margin: 6 }}
              />
            </h2>
          </div>

          <div className="nft-card-con">
            {artistsData.length > 0 &&
              artistsData.map((ele, index) => {
                return (
                  <div
                    className="turning-screens music-artists 2xl:w-2/12 xl:w-5/12 lg:w-3/12 md:w-4/12  "
                    key={index}
                  >
                    <MusicArtist ele={ele} />
                  </div>
                );
              })}

            {loading && (
              <div className="flex 	w-full my-6  " style={{ flexWrap: 'wrap' }}>
                <MusicArtistSkeleton />
                <MusicArtistSkeleton />
                <MusicArtistSkeleton />
                <MusicArtistSkeleton />
                <MusicArtistSkeleton />
                <MusicArtistSkeleton />
              </div>
            )}
          </div>
          <div className="viewmore__link">
            {artistsData.length > 3 && (
              <a href="/artists" className="view_box_btn mx-auto">
                View more
              </a>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
